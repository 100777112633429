import { Controller } from 'stimulus'
import { useIntersection } from 'stimulus-use'

export default class extends Controller {
  options = {
    threshold: 0, // default
  }

  connect() {
    useIntersection(this, this.options)
  }

  appear(entry) {
    // callback automatically triggered when the element
    // intersects with the viewport (or root Element specified in the options)
    if (this.data.get("event") == 'view'){
      this.mark_as_viewed();
      this.feed_post_view_event('appear');
    }
  }

  disappear(entry) {
    // callback automatically triggered when the element
    // leaves the viewport (or root Element specified in the options)
    if (this.data.get("event") == 'view'){
      this.feed_post_view_event('disappear');
    }
  }

  // POST to post_view model
  mark_as_viewed() {
    var user_post_arr = this.data.get("postview").split(':');
    var user_id = user_post_arr.shift(); //or arr[arr.length-1];
    var post_id = user_post_arr.pop(); //or arr[0];
    // console.log(this.data.get("postview"));
    // console.log("user_id " + user_id);
    // console.log("post_id " + post_id);
    
    var data = { 
        user_id: user_id,
        post_id: post_id
    }
    // console.log(data)
    // console.log('viewed')
    
    // TODO: logic to update once for every post
    if (true) {
      fetch("/api/utilities/post-view", {
          method: 'POST',
          headers:  {
              "Content-Type": "application/json",
              "Accept": "application/json",
              'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content')
          },
          body: JSON.stringify(data)
      }).then(
          response => response.json()
      ).then((resp) => {
          // console.log(resp)
      });
    }
  }

  // Feed Post View Event
  feed_post_view_event(action, sessionStorageKey) {
    // Logic on appear
    if (action == 'appear') {
      // Save data to sessionStorage if not exist
      
      if (sessionStorage.getItem(`${this.data.get("postview")}`)) {
        // 
      } else {
        sessionStorage.setItem(`${this.data.get("postview")}`, `${Date.now()}:null`);
      }
    }

    // Logic on disappear
    if (action == 'disappear') {
      // console.log(this.data.get("postview"))
      // console.log('disappeared')
  
      // Get saved data from sessionStorage...
      let value = sessionStorage.getItem(`${this.data.get("postview")}`);
      // ...and set (replace null) disappear timestamp
      let updated_value = value.replace('null', `${Date.now()}`);
      sessionStorage.setItem(`${this.data.get("postview")}`, `${updated_value}`);
      this.post_feed_post_view_event(this.data.get("postview"), this.data.get("type"));
    }
  }

  // POST Feed Post View Event
  post_feed_post_view_event(key, type) {

    if (sessionStorage.getItem(`${key}`)) {

      var user_post_arr = key.split(':');
      // console.log(`to be sent: ${user_post_arr}`);
      var user_id = user_post_arr.shift(); //or arr[arr.length-1];
      var post_id = user_post_arr.pop(); //or arr[0];
      let value_arr = sessionStorage.getItem(`${key}`).split(':');
      // console.log(value_arr);
      var started = value_arr.shift(); //or arr[arr.length-1];
      // console.log(started);
      var ended = value_arr.pop(); //or arr[0];
      // console.log(type);
      
      var data = { 
        user_id: user_id,
        post_id: post_id, 
        event: 'user_feed_post_view_duration',
        payload_int: parseInt(ended) - parseInt(started),
        timestamp: Date.now()
      }
      // console.log(data['payload_int']);

      if (data['payload_int'] > 100 ){
        fetch("/api/utilities/user_event", {
          method: 'POST',
          headers:  {
              "Content-Type": "application/json",
              "Accept": "application/json",
              'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content')
          },
          body: JSON.stringify(data)
        }).then(
            response => response.json()
        ).then((resp) => {
            // console.log(resp);
        });
      }
      // Remove item from sessionStorage
      sessionStorage.removeItem(key);
    }

  }

  // POST Feed Post Click Event
  click_event(){
    var user_post_arr = this.data.get("postview").split(':');
    var user_id = user_post_arr.shift(); //or arr[arr.length-1];
    var post_id = user_post_arr.pop(); //or arr[0];

    // data hash
    var data = { 
      user_id: user_id,
      post_id: post_id, 
      event: 'user_feed_post_click',
      timestamp: Date.now()
    }

    // hit endpoint
    fetch("/api/utilities/user_event", {
      method: 'POST',
      headers:  {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content')
      },
      body: JSON.stringify(data)
    }).then(
        response => response.json()
    ).then((resp) => {
        // console.log(resp);
    });
  }

  // POST Feed Post Share Event
  share_event(){
    var user_post_arr = this.data.get("postview").split(':');
    var user_id = user_post_arr.shift(); //or arr[arr.length-1];
    var post_id = user_post_arr.pop(); //or arr[0];

    // data hash
    var data = { 
      user_id: user_id,
      post_id: post_id, 
      event: 'user_feed_post_share',
      timestamp: Date.now()
    }

    // console.log(data);
    // hit endpoint
    fetch("/api/utilities/user_event", {
      method: 'POST',
      headers:  {
          "Content-Type": "application/json",
          "Accept": "application/json",
          'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content')
      },
      body: JSON.stringify(data)
    }).then(
        response => response.json()
    ).then((resp) => {
        // console.log(resp);
    });
  }


}
