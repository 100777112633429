import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ "code", "email_verify_button", "send_code_button" ]

  connect() {
    
  }

  verify() {
    const code = this.codeTarget.value;

    var data = { 
      code: code
    }

    fetch("/api/utilities/user-verify-email-code", {
        method: 'POST',
        headers:  {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content')
        },
        body: JSON.stringify(data)
    }).then(
        response => response.json()
    ).then((resp) => {
        // console.log(resp)
        if (resp.msg == 'Email confirmed') { Turbo.visit(window.location.toString()) }
    });
  }

  send_code_for_ws_email(event) {

    var data = { 
      // email: send_code
    }

    // console.log(event.target.dataset.methodPathParam);

    fetch(event.target.dataset.methodPathParam, {
        method: 'POST',
        headers:  {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content')
        },
        body: JSON.stringify(data)
    }).then(
        response => response.json()
      ).then((resp) => {
          console.log(resp);
          event.target.parentNode.classList.add("d-none");
          var desktop_form = document.getElementById("ws_email_verification_section_desktop");
          if (desktop_form != undefined){
            desktop_form.classList.remove("d-none");
            desktop_form.classList.add("d-flex");
          } 
          var mobile_form = document.getElementById("ws_email_verification_section_mobile");
          if (mobile_form != undefined){
            mobile_form.classList.remove("d-none");
          }
          // if (resp.msg == 'Email confirmed') { Turbo.visit(window.location.toString()) }
      });
  }

  send_code_for_backup_email(event) {

    var data = { 
      // email: send_code
    }

    // console.log(event.target.dataset.methodPathParam);

    fetch(event.target.dataset.methodPathParam, {
        method: 'POST',
        headers:  {
            "Content-Type": "application/json",
            "Accept": "application/json",
            'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content')
        },
        body: JSON.stringify(data)
    }).then(
        response => response.json()
      ).then((resp) => {
          console.log(resp);
          // event.target.parentNode.classList.add("d-none");
          // var desktop_form = document.getElementById("ws_email_verification_section_desktop");
          // if (desktop_form != undefined){
          //   desktop_form.classList.remove("d-none");
          //   desktop_form.classList.add("d-flex");
          // } 
          // var mobile_form = document.getElementById("ws_email_verification_section_mobile");
          // if (mobile_form != undefined){
          //   mobile_form.classList.remove("d-none");
          // }
          // if (resp.msg == 'Email confirmed') { Turbo.visit(window.location.toString()) }
      });
  }

  verify_button_set(){
    var email_verification_code = this.codeTarget
    var email_verify_button = this.email_verify_buttonTarget
    if (email_verification_code.value.length == 6){
      email_verify_button.disabled = false;
    } else {
      email_verify_button.disabled = true;
    }
  }

  disable_resend(event){
    // console.log(event)
    event.srcElement.classList.add("d-none")
  }

  disable_send_code(event){
    var send_code_button = this.send_code_buttonTarget
    var email = event.target.value
    if (email.includes('@')){
      send_code_button.disabled = false
    } else {
      send_code_button.disabled = true
    }
  }
}
