import { Controller } from 'stimulus'
import { validate } from 'validate.js';

export default class extends Controller {

  connect() {
    
  }
  
  populate_emails() {
    const raw_text = this.element.querySelectorAll('textarea')[0].value;
    var validated_emails = [];

    // Identify delimiter
    var delimiter = ''
    if (raw_text.indexOf(',') > 0) {
      delimiter = ','
    } else if (raw_text.indexOf('\n') > 0) {
      delimiter = '\n'
    }
    
    // If delimiter identified
    if (delimiter != '') {
      // Create array of emails
      var emails_arr = raw_text.split(delimiter);
      // console.log(emails_arr);
      // Loop emails array
      for (const email of emails_arr){
        // Validate email
        if (this.validate_email(email.trim()) == undefined) {
          // Exclude empty lines
          if (email != '' && email != null) {
            validated_emails.push(email.trim())
          }
        } 
      }
      // console.log(validated_emails);
      this.element.querySelectorAll('#emails')[0].value = validated_emails.join(',');
      console.log(this.element.querySelectorAll('#emails')[0].value);
      if (validated_emails.length > 0) {
        document.getElementById('bulk-add-submit-btn').disabled = false;
      } else {
        document.getElementById('bulk-add-submit-btn').disabled = true;
      }
    } else { // if delimiter is not present 
      // console.log(this.validate_email(raw_text.trim()) == undefined);
      if (this.validate_email(raw_text.trim()) == undefined) {
        validated_emails.push(raw_text.trim());
        document.getElementById('bulk-add-submit-btn').disabled = false;
      } else {
        document.getElementById('bulk-add-submit-btn').disabled = true;
      }
    }
    console.log(validated_emails);
    
  }

  // see https://validatejs.org/#validators-email
  validate_email(email) {
    var constraints = {
      from: {
        email: true
      }
    };

    return validate({from: email}, constraints);
  }

}
