import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "text", "url", "comment" ]

    static delayTimer

    get_link_preview() {
        const url_element = this.urlTarget
        const url = url_element.value

        var data = {
            url: url
        }

        clearTimeout(this.delayTimer);
        this.delayTimer = setTimeout(function() {
            // Disable URL input - Show spinner
            document.getElementById("post_url").readOnly = true
            document.getElementById("reset-url-button").classList.toggle("d-none")
            fetch("/api/utilities/generate-link-preview", {
                method: 'POST',
                headers:  {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").getAttribute('content')
                },
                body: JSON.stringify(data)
            }).then(
                response => response.json()
            ).then((resp) => {
                // Draw preview
                if (resp.status == 'Error') {
                    document.getElementById("post_url").readOnly = false
                    document.getElementById("post_url").classList.add("is-invalid")
                    document.getElementById("reset-url-button").classList.toggle("d-none")
                } else {
                    if (resp.link_preview.image != null){
                        document.getElementById("preview_image").src = resp.link_preview.image;
                        document.getElementById("post_temp_media").value = resp.link_preview.image;
                    } else {
                        document.getElementById("preview_image").src = "/no-image-available.png";
                        document.getElementById("post_temp_media").value = "/no-image-available.png";
                    }

                    document.getElementById("preview_title").innerHTML = resp.link_preview.title;
                    document.getElementById("preview_description").innerHTML = resp.link_preview.description.substring(0, 250);
                    document.getElementById("preview_source").innerHTML = resp.link_preview.publisher;
                    // Update hidden fields
                    document.getElementById("post_title").value = resp.link_preview.title;
                    document.getElementById("post_description").value = resp.link_preview.description.substring(0, 250);
                    document.getElementById("post_source").value = resp.link_preview.publisher;
                    //
                    document.getElementById("reset-url-button").classList.toggle("d-none")
                    document.getElementById("post_url").classList.add("is-valid")
                    document.getElementById("post_url").classList.remove("is-invalid")
                    document.getElementById("suggest_button").disabled = false;
                }
            });
        }, 1000); // Will do the ajax stuff after 1000 ms, or 1 s
    }

    draw_comment() {
        const text_element = this.textTarget
        var text = text_element.value
        var links = text.match(/\S+\.\S+/g);
        if (links !== null){
            links.forEach( element => {
                if (element.includes("ftp://") || element.includes("http://") || element.includes("https://")){
                    text = text.replace(element, '<a href="'+ element +'" target="_blank">'+ element +"</a>");
                } else {
                    text = text.replace(element, '<a href="'+ "//" + element +'" target="_blank">'+ element +"</a>");
                }
                
            });
        }
        document.getElementById("preview_comment").innerHTML = text;
    }
}