// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Make jQuery available everywhere
global.$ = require('jquery')
global.jQuery = require('jquery')

import Rails from "@rails/ujs" // Hotwire/Turbo docs do not recommend to remove ujs (they can co-exist as long as you don't use 'remote:true' on forms) 
import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails"
import "channels"
import Cookies from 'js-cookie'
import "packs/browser_timezone"
 
import "controllers"

require("@popperjs/core")
import "bootstrap"
window.bootstrap = require("bootstrap")
import { Tooltip } from "bootstrap"

// import "../stylesheets/application"
 
require("@nathanvda/cocoon") // Nested forms 

Rails.start()
ActiveStorage.start()
window.Cookies = Cookies // Must be set along with the import declaration above to be app-wide available.


// App-wide TURBO(on load) related tasks ////////////////////////////////////////////////////////////////
document.addEventListener("turbo:load", function() {
    // Print if on localhost (debug)
    if (location.host.includes('localhost')) {
        console.log("Turbo")
    }
    sessionStorage.clear();

    // Init Select2 Dropdowns //////////////////////////////////////////////////////////////////
    var select2 = $('.selectpicker');
    select2.length && select2.each(function (index, value) {
        var $this = $(value);
        var options = $.extend({
        theme: 'classic'
        }, $this.data('options'));
        $this.select2(options);
    });

    // Init bootstrap5 tooltips
    // Initialize tooltips in intervals to initialize tooltips from appended posts in feed when scrolling
    var tooltipList = []; // Init outside setInterval to avoid undefined error when chacking length before first round of tooltip initialization ('if (tooltipList.length > 0)' below)
    setInterval(() => {
        var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))

        // console.log(tooltipList.length);
        // Destroy initialized tooltips (re-initialization of already initialized tootltips causes persisted tooltips)
        if (tooltipList.length > 0) {
            tooltipList.forEach(function (tooltip, index) {
                tooltip.dispose();
            });
        }

        tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
            return new Tooltip(tooltipTriggerEl);
        })
    }, 3000);

});
