import { Controller } from "stimulus"
// import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "search_form", "date_range_form", "clear_date_range_form" ] 

  initialize () {
    // is called once per controller
  }

  connect () {
    // is called every time the controller is connected to the DOM.
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.search_formTarget.requestSubmit()
    }, 450)
  }

  date_range() {
    var dr = this.date_range_formTarget.children[0].value;
    console.log(dr);
    if (dr.length == 24) {
      console.log('dr');
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.date_range_formTarget.requestSubmit()
      }, 150)
    }
  }

  clear_date_range() {
    // console.log('clear');
    var dr_element = document.getElementById('groups_date_range');
    dr_element.value = '';
    // // console.log(dr_element.value);
    this.clear_date_range_formTarget.requestSubmit()
  }
}