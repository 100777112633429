import { Controller } from "stimulus"
// import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [ "search_form", "loading_spinner" ] 

  initialize () {
    // is called once per controller
  }

  connect () {
    // is called every time the controller is connected to the DOM.
  }

  search() {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.loading_spinnerTarget.classList.remove('d-none');
      this.search_formTarget.requestSubmit();
    }, 600)
  }
}